export default function ({ size }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10ZM14.03 6.97C14.1705 7.11063 14.2493 7.30125 14.2493 7.5C14.2493 7.69875 14.1705 7.88937 14.03 8.03L9.03 13.03C8.88937 13.1705 8.69875 13.2493 8.5 13.2493C8.30125 13.2493 8.11063 13.1705 7.97 13.03L5.97 11.03C5.89631 10.9613 5.83721 10.8785 5.79622 10.7865C5.75523 10.6945 5.73319 10.5952 5.73141 10.4945C5.72963 10.3938 5.74816 10.2938 5.78588 10.2004C5.8236 10.107 5.87974 10.0222 5.95096 9.95096C6.02218 9.87974 6.10701 9.8236 6.2004 9.78588C6.29379 9.74816 6.39382 9.72963 6.49452 9.73141C6.59522 9.73318 6.69454 9.75523 6.78654 9.79622C6.87854 9.83721 6.96134 9.89631 7.03 9.97L8.5 11.44L10.735 9.205L12.97 6.97C13.1106 6.82955 13.3012 6.75066 13.5 6.75066C13.6988 6.75066 13.8894 6.82955 14.03 6.97Z"
        fill="currentColor"
      />
    </svg>
  )
}
