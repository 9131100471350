export default function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7.33301C12 5.44767 12 4.50434 11.414 3.91901C10.8287 3.33301 9.88533 3.33301 8 3.33301H6C4.11467 3.33301 3.17133 3.33301 2.586 3.91901C2 4.50434 2 5.44767 2 7.33301V10.6663C2 12.5517 2 13.495 2.586 14.0803C3.17133 14.6663 4.11467 14.6663 6 14.6663H8C9.88533 14.6663 10.8287 14.6663 11.414 14.0803C12 13.495 12 12.5517 12 10.6663V7.33301Z"
        stroke="#0E121B"
        stroke-width="1.5"
      />
      <path
        d="M12 12.6663C12.5304 12.6663 13.0391 12.4556 13.4142 12.0806C13.7893 11.7055 14 11.1968 14 10.6663V6.66634C14 4.15234 14 2.89501 13.2187 2.11434C12.438 1.33301 11.1807 1.33301 8.66667 1.33301H6C5.46957 1.33301 4.96086 1.54372 4.58579 1.91879C4.21071 2.29387 4 2.80257 4 3.33301"
        stroke="#0E121B"
        stroke-width="1.5"
      />
    </svg>
  )
}
